<template>
  <div>
    <ui-component-modal
      :modalTitle="$t('Components.Vouchers.ModalEditOptions.Main_Title')"
      :onClickCancel="onClickCancel"
      :showModal="showModal"
      :onClickSave="saveVoucher"
      :isSaving="isSaving"
      :isSavingSuccess="isSavingSuccess"
      :isSavingError="isSavingError"
      :hideFooterButtons="isSavingError || isSavingSuccess"
      modalSize="large"
      @closeModal="onClickCancel"
    >
      <template v-slot:content>
        <div class="form-options columns">
          <div class="search-add column column-search">
            <div class="search-wrapper has-margin-bottom">
              <!-- search input -->
              <input
                v-on:keyup.enter="search"
                class="input"
                type="text"
                v-model="searchTerm"
                placeholder="Search for options"
              />
              <!-- search button -->
              <button @click="search" class="button is-primary">
                <a>
                  <span class="icon">
                    <font-awesome-icon
                      :icon="['fas', 'search']"
                      style="color: white"
                    />
                  </span>
                </a>
              </button>
            </div>
            <div v-if="locationId > 0" class="tabs is-small is-boxed">
              <ul>
                <li
                  @click="changeInfo('FB')"
                  :class="{ 'is-active': infoTab === 'FB' }"
                >
                  <a>{{
                    $t('Components.Vouchers.ModalEditOptions.Tab_Food-beverage')
                  }}</a>
                </li>
                <li
                  @click="changeInfo('AV')"
                  :class="{ 'is-active': infoTab === 'AV' }"
                >
                  <a>{{
                    $t(
                      'Components.Vouchers.ModalEditOptions.Tab_Audiovisual-equipment'
                    )
                  }}</a>
                </li>
                <li
                  @click="changeInfo('Parking')"
                  :class="{ 'is-active': infoTab === 'Parking' }"
                >
                  <a>{{
                    $t('Components.Vouchers.ModalEditOptions.Tab_Parking')
                  }}</a>
                </li>
                <li
                  @click="changeInfo('Other')"
                  :class="{ 'is-active': infoTab === 'Other' }"
                >
                  <a>{{
                    $t('Components.Vouchers.ModalEditOptions.Tab_Other')
                  }}</a>
                </li>
              </ul>
            </div>
            <div class="tabs-info">
              <ui-loader v-if="isLoading === true" />
              <div
                class="row"
                v-for="(item, index) in displayItems"
                :key="index"
              >
                <div class="item">{{ item.Name }}</div>
                <div class="button-group">
                  <button
                    @click="addOption(item)"
                    class="button is-small is-primary"
                  >
                    <span class="icon is-small">
                      <font-awesome-icon
                        :icon="['fas', 'plus']"
                        style="color: white"
                      />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="menu-options column">
            <div class="selected-options-wrapper">
              <div class="title is-3">
                {{ $t('Components.Vouchers.ModalEditOptions.Sub_Title') }}
              </div>

              <message
                v-if="mVoucher.Options.length === 0"
                :message="'No options selected'"
              />

              <div>
                <div
                  v-for="(option, index) in mVoucher.Options"
                  :key="index"
                  class="row"
                >
                  <EditVoucherOption
                    @optionDeleted="optionDeleted"
                    :option="option"
                    :index="index"
                    :voucherType="mVoucher.Type"
                    class="box has-margin-bottom"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ui-component-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import optionProvider from '@/providers/option'
import voucherProvider from '@/providers/voucher'

import Message from '@/components/UI/Message'
import EditVoucherOption from '@/components/Vouchers/EditVoucherOption'

export default {
  components: {
    message: Message,
    EditVoucherOption,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      searchTerm: '',
      infoTab: 'FB',
      locationOptions: [],
      actions: [],
      isLoading: false,
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      mVoucher: null,
    }
  },

  computed: {
    ...mapState('voucherStore', ['voucher']),

    filteredItems() {
      let result = this.locationOptions

      if (this.locationId > 0)
        result = this.locationOptions.filter(
          (item) => item.Location.CategoryId === this.infoTab
        )
      return result
    },

    displayItems() {
      let result = []
      if (this.filteredItems.length > 0 && this.mVoucher) {
        for (let i = 0; i < this.filteredItems.length; i++) {
          let optionIndex = this.mVoucher.Options.findIndex(
            (o) => o.OptionId === this.filteredItems[i].Id
          )
          if (optionIndex === -1) {
            result.push(this.filteredItems[i])
          }
        }
      }
      return result
    },
  },

  created() {
    let self = this

    if (this.locationId > 0) {
      this.channelId = 0
    }

    self.mVoucher = JSON.parse(JSON.stringify(self.voucher))

    if (self.mVoucher.Type !== 'Package') {
      self.actions.push({ Id: 0, Name: 'Optional' })
      self.actions.push({ Id: 1, Name: 'Pre selected' })
    }
    self.actions.push({ Id: 2, Name: 'Required' })
    self.actions.push({ Id: -1, Name: 'Remove' })

    self.getOptions()
  },

  methods: {
    ...mapMutations('voucherStore', ['setVoucher']),

    changeInfo(tab) {
      this.infoTab = tab
    },

    getOptions() {
      if (this.locationId > 0) {
        this.getLocationOptions()
      } else {
        this.getChannelOptions()
      }
    },

    getLocationOptions() {
      this.isLoading = true
      optionProvider.methods
        .getLocationOptions(this.locationId, 0, this.searchTerm)
        .then((response) => {
          this.locationOptions = response.data.Results
        })
        .catch((e) => {})
        .finally(() => {
          this.isLoading = false
        })
    },

    getChannelOptions() {
      this.isLoading = true
      optionProvider.methods
        .getChannelOptions(this.channelId, 0, this.searchTerm)
        .then((response) => {
          this.locationOptions = response.data.Results
        })
        .catch((e) => {})
        .finally(() => {
          this.isLoading = false
        })
    },

    addOption(option) {
      let self = this

      self.mVoucher.Options.push({
        OptionId: option.Id,
        LocationId: self.locationId,
        Name: option.Name,
        CurrencyId: option.CurrencyId,
        DiscountType: 'Price',
        DiscountPrice: 0,
        DiscountPercentage: -1,
        RequiredItem: self.mVoucher.Type === 'Package' ? 2 : 0,
      })
    },

    optionDeleted(option) {
      let index = this.mVoucher.Options.findIndex(
        (o) => o.OptionId === option.OptionId
      )
      if (index > -1) {
        Vue.delete(this.mVoucher.Options, index)
      }
    },

    search() {
      let self = this
      this.isLoading = true
      this.locationOptions = []
      this.getOptions()
    },

    saveVoucher() {
      this.isSaving = true

      this.mVoucher.LocationId = this.locationId

      voucherProvider.methods
        .saveVoucher(this.mVoucher)
        .then((response) => {
          if (response.status === 200) {
            this.setVoucher(response.data)
            this.isSavingSuccess = true

            setTimeout(() => {
              this.$emit('voucherEdited', response.data)
              this.onClickCancel()
            }, 1500)
          }
        })
        .catch((error) => {
          //console.log(error)
          this.isSavingError = true
        })
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/template';
.form-options {
  .search-add {
    display: flex;
    flex-direction: column;
    &.column-search {
      width: 33%;
    }
    .search-wrapper {
      display: flex;
      .button {
        margin-left: 5px;
        &.green {
          background-color: $green;
        }
      }
    }
    .tabs {
      margin-bottom: 0;
    }
    .tabs-info {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      border-radius: 6px;
      border: 1px solid $grey-lighter;
      border-top: none;
      height: 300px;
      .row {
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px dotted $grey-lighter;
        padding: 5px 10px;
        .item {
          width: 90%;
          flex-wrap: wrap;
        }
        .button-group {
          position: absolute;
          right: 10px;
          width: 21px;
          height: 21px;
          .button {
            padding: 0;
            width: 100%;
            height: 100%;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
