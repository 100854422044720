<template>
  <div>
    <div class="name has-text-weight-bold">{{ option.Name }}</div>
    <div class="input-wrapper has-margin-top">
      <div class="select">
        <select v-model="option.RequiredItem">
          <option
            v-for="action in actions"
            :key="action.Id"
            :selected="action.Id === option.RequiredItem"
            :value="action.Id"
          >
            {{ action.Name }}
          </option>
        </select>
      </div>

      <!-- TYPE -->
      <div v-if="voucherType !== 'Package'" class="item-input">
        <div class="select">
          <select @change="setDiscountType" v-model="option.DiscountType">
            <option
              v-for="calculationType in calculationTypes"
              :key="calculationType.Id"
              :selected="calculationType.Name === option.DiscountType"
              :value="calculationType.Name"
            >
              {{ calculationType.Name }}
            </option>
          </select>
        </div>
      </div>
      <div class="item-input input-value">
        <div v-if="option.DiscountType === 'Price'" class="field has-addons">
          <p class="control">
            <a class="button is-static" v-html="option.CurrencySymbol"></a>
          </p>
          <p class="control">
            <input
              type="text"
              v-model.number="option.DiscountPrice"
              class="input"
            />
          </p>
        </div>

        <div
          v-if="option.DiscountType === 'Percentage'"
          class="field has-addons"
        >
          <p class="control">
            <input
              type="text"
              v-model.number="option.DiscountPercentage"
              class="input"
            />
          </p>
          <p class="control">
            <a class="button is-static">%</a>
          </p>
        </div>
      </div>

      <a @click="deleteOption(option)">
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'trash-alt']" style="color:red" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      default: null,
    },

    voucherType: {
      type: String,
      default: 'Voucher',
    },
  },

  data() {
    return {
      actions: [],
      calculationTypes: [],
    }
  },

  created() {
    let self = this

    if (self.voucherType !== 'Package') {
      self.actions.push({ Id: 0, Name: 'Optional' })
      self.actions.push({ Id: 1, Name: 'Pre selected' })
    }
    self.actions.push({ Id: 2, Name: 'Required' })
    self.actions.push({ Id: -1, Name: 'Remove' })

    self.calculationTypes.push({ Id: 0, Name: 'Percentage' })
    self.calculationTypes.push({ Id: 1, Name: 'Price' })
  },

  methods: {
    deleteOption(option) {
      this.$emit('optionDeleted', option)
    },

    setDiscountType() {
      let self = this
      if (self.option.DiscountType === 'Price') {
        self.option.DiscountPercentage = -1
        self.option.DiscountPrice = 0
      }
      if (self.option.DiscountType === 'Percentage') {
        self.option.DiscountPrice = -1
        self.option.DiscountPercentage = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  display: flex;
  & > * {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }

  .input-value {
    div {
      display: flex;
      align-items: center;
    }
  }
}
</style>
